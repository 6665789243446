<template>
    <div class="finish-wrap">
        <div class="logo">
            <img src="../assets/images/bentley_logo_a-c1@2x.png"  alt="" />
        </div>
        <div class="info-box">
            <span>{{courseName}}</span>
        </div>
        <div class="course-box">
            <!-- <div v-for="item in detailList" :key="item.id" class="item-row">
                <div class="item-box" @click="mapClick(item)">
                    <div class="row1">
                        <span>{{ getClassDate(item.classStartDate) }}</span>
                        <span style="margin-left: 8px">-</span>
                        <span style="margin-left: 8px">{{ getClassDate(item.classEndDate) }}</span>
                        <span style="margin-left: 10px; color: rgba(0, 0, 0, 0.5)">{{ getDaysBetween(item) + '天' }}</span>
                    </div>
                    <div v-if="item.classAddress" class="row2">
                        <van-icon name="location-o" />
                        <span style="margin-left: 3px">{{ '地址：' + item.classAddress }}</span>
                    </div>
                </div>
                <div :class="['btn', {'disabled': enrollmentStatus(item)}]" @click="!enrollmentStatus(item) && handleEnrollment(item) ">报名</div>
            </div> -->
            <div v-for="item in detailList" :key="item.id" class="item-row">
                <div v-if="item.classStartDate > '2023-01-15'" class="item-box">
                    <div class="row1">
                        <span class="span1">本期课程信息</span>
                        <div :class="['btn', {'disabled': enrollmentStatus(item)}]" @click="!enrollmentStatus(item) && handleEnrollment(item)">去报名</div>
                    </div>
                    <div class="row2">
                        <span class="span2">{{(item.classAddress) ? '上课地点：' : '课程方式：'}}</span>
                        <span class="span3">{{(item.classAddress) ? item.classAddress : '虚拟课堂'}}</span>
                    </div>
                    <div class="row2">
                        <span class="span2">上课时间：</span>
                        <span class="span3">{{ classDateText(item)}}</span>
                    </div>

                    <div class="row2" v-if="item.showX9_221213">
                        <span class="span2"></span>
                        <span class="span3">2022年12月13日 9:00-16:00</span>
                    </div>
                    <div class="row2" v-if="item.showX9_221215">
                        <span class="span2"></span>
                        <span class="span3">2022年12月15日 9:00-16:00</span>
                    </div>

                    <div class="row2" v-if="item.showX2_221214">
                        <span class="span2"></span>
                        <span class="span3">2022年12月14日 9:00-16:00</span>
                    </div>
                    <div class="row2" v-if="item.showX2_221216">
                        <span class="span2"></span>
                        <span class="span3">2022年12月16日 9:00-16:00</span>
                    </div>

                    <div class="row2" v-if="item.showGH5_221213">
                        <span class="span2"></span>
                        <span class="span3">2022年12月13日 9:00-16:00</span>
                    </div>
                    <div class="row2" v-if="item.showGH5_221217">
                        <span class="span2"></span>
                        <span class="span3">2022年12月17日 9:00-16:00</span>
                    </div>

                    <!-- <div class="row2" v-if="item.showClass70607">
                        <span class="span2"></span>
                        <span class="span3">2022年6月7日 9:00-10:30</span>
                    </div>
                    <div class="row2" v-if="item.showClass70609">
                        <span class="span2"></span>
                        <span class="span3">2022年6月9日 9:00-10:30</span>
                    </div>
                    <div class="row2" v-if="item.showClass70614">
                        <span class="span2"></span>
                        <span class="span3">2022年6月14日 9:00-10:30</span>
                    </div>
                    <div class="row2" v-if="item.showClass70616">
                        <span class="span2"></span>
                        <span class="span3">2022年6月16日 9:00-10:30</span>
                    </div> -->
                    <div class="row2" v-if="item.showClass71207">
                        <span class="span2"></span>
                        <span class="span3">2022年12月8日 9:00-10:30</span>
                    </div>

                    <div class="row4">
                        <span class="span2">报名状态：</span>
                        <van-progress :percentage="item.realCount * 100 / item.limitCount"  class="progress" stroke-width="16" :show-pivot="false" color="rgba(0,50,32,0.7)" track-color="#d2d2d4"/>
                        <!-- <div v-for="i in item.limitCount" :key=i>
                            <div v-if="i <= item.realCount">
                                <div class="square2"/>
                            </div>
                            <div v-else>
                                <div class="square1"/>
                            </div>
                        </div> -->
                        <span class="span2">{{'(' + item.realCount + '/' + item.limitCount + ')'}}</span>
                    </div>
                </div>
            </div>
            <div class="avatar">
                <img v-if="courseImageUrl" :src="courseImageUrl" class="img">
            </div>
        </div>
    </div>
</template>
<script>
import {Landing} from '@/network'
import { Dialog, Icon, Progress } from 'vant';
import moment from 'moment';

export default {
    data() {
        return {
            hubId: sessionStorage.getItem('hubId') || null,
            phone: sessionStorage.getItem('phone') || null,
            courseId: null,
            courseName: null,
            uesrInfo: {},
            detailList: [],
            courseImageUrl: '',
            enrollmentDetailList: [],
        }
    },
    components: {
        VanIcon: Icon,
        vanProgress: Progress,
    },
    async created() {
        this.courseId = this.$route.params.courseId
        this.uesrInfo = await Landing.getUserInfo({phone: this.phone})
        const course = await Landing.getCourse({id: this.courseId})
        if (course) {
            this.courseName = course.courseName
            this.courseImageUrl = course.courseImageUrl
        }
        const {data} = await Landing.getCourseDetailList({pageNum:1, pageSize:9999, courseId: this.courseId})
        if (data) {
            this.detailList = data;
        }
        if (course.courseNo == '销售课程9') {
            this.detailList.forEach(detail => {
                detail.showX9_221213 = false
                detail.showX9_221215 = false
                if (moment(detail.classStartDate).format('YYYY年M月D日') == '2022年12月12日') {
                    detail.showX9_221213 = true
                }
                if (moment(detail.classStartDate).format('YYYY年M月D日') == '2022年12月14日') {
                    detail.showX9_221215 = true
                }
            });
        }
        if (course.courseNo == '销售课程2') {
            this.detailList.forEach(detail => {
                detail.showX2_221214 = false
                detail.showX2_221216 = false
                if (moment(detail.classStartDate).format('YYYY年M月D日') == '2022年12月13日') {
                    detail.showX2_221214 = true
                }
                if (moment(detail.classStartDate).format('YYYY年M月D日') == '2022年12月15日') {
                    detail.showX2_221216 = true
                }
            });
        }
        if (course.courseNo == '管理混合课程5') {
            this.detailList.forEach(detail => {
                detail.showGH5_221213 = false
                detail.showGH5_221217 = false
                if (moment(detail.classStartDate).format('YYYY年M月D日') == '2022年12月12日') {
                    detail.showGH5_221213 = true
                }
                if (moment(detail.classStartDate).format('YYYY年M月D日') == '2022年12月16日') {
                    detail.showGH5_221217 = true
                }
            });
        }
        if (course.courseNo == '销售课程7') {
            this.detailList.forEach(detail => {
                detail.showClass70607 = false
                detail.showClass70609 = false
                detail.showClass70614 = false
                detail.showClass70616 = false
                detail.showClass71207 = false
                if (moment(detail.classStartDate).format('YYYY年M月D日') == '2022年6月6日') {
                    detail.showClass70607 = true
                }
                if (moment(detail.classStartDate).format('YYYY年M月D日') == '2022年6月8日') {
                    detail.showClass70609 = true
                }
                if (moment(detail.classStartDate).format('YYYY年M月D日') == '2022年6月13日') {
                    detail.showClass70614 = true
                }
                if (moment(detail.classStartDate).format('YYYY年M月D日') == '2022年6月15日') {
                    detail.showClass70616 = true
                }
                if (moment(detail.classStartDate).format('YYYY年M月D日') == '2022年12月7日') {
                    detail.showClass71207 = true
                }
                // console.warn('xxxxxxxxxxx courseNo = ', course.courseNo)
                // console.warn('xxxxxxxxxxx showClass70607 = ', detail.showClass70607)
                // console.warn('xxxxxxxxxxx showClass70609 = ', detail.showClass70609)
                // console.warn('xxxxxxxxxxx showClass70614 = ', detail.showClass70614)
            });
        }
        const dlist = await Landing.getEnrollmentDetailList({grpId: this.hubId})
        if (dlist) this.enrollmentDetailList = dlist.data
        // this._test_isRepeat()
    },
    methods: {
        btnstatus(item) {
            return true
        },
        classDateText(detail) {
            if (detail && detail.classStartDate && detail.classEndDate){
                console.log(moment(detail.classStartDate).format('YYYY年M月D日')+' _'+ moment(detail.classEndDate).format('YYYY年M月D日')+'')
                if(moment(detail.classStartDate).format('YYYY年M月D日')+'' == moment(detail.classEndDate).format('YYYY年M月D日')+'') {
                    return moment(detail.classStartDate).format('YYYY年M月D日 H:mm') + '-' + moment(detail.classEndDate).format(' H:mm')
                }else{
                    return moment(detail.classStartDate).format('YYYY年M月D日 H:mm') + '-\n' + moment(detail.classEndDate).format('YYYY年M月D日 H:mm')
                }
            }
        },

        getClassDate(classDate) {
            return moment(classDate).format('M月D日 H:mm') 
        },
        getWeek(classDate) {
            let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
            let wk = new Date(moment(classDate).format('YYYY/MM/DD')).getDay()
            return weeks[wk]
        },
        getDaysBetween(courseDetail) {
          var startDate = new Date(moment(courseDetail.classStartDate).format('YYYY/MM/DD'))
          var endDate = new Date(moment(courseDetail.classEndDate).format('YYYY/MM/DD'))
          var days=(endDate - startDate)/(1*24*60*60*1000) + 1;
          return  days;
        },
        handleEnrollment(courseDetail) {
            Dialog.confirm({
                width: '85%',
                allowHtml: true,
                className: 'dialog-alert-font-class-course-detail',
                messageAlign: 'center',
                title: '确认报名',
                message: '确定报名此课程?'
            }).then(() => {
                if (this.isRepeatAppointment(courseDetail)) {
                    Dialog.alert({
                        width: '85%',
                        allowHtml: true,
                        className: 'dialog-alert-font-class-course-detail',
                        messageAlign: 'left',
                        title: '非常抱歉',
                        message: '您选择的时段，已经有预约的课程，请选择其他时段'
                    })
                } else {
                    Landing.addEnrollmentDetail({
                        phone: this.phone,
                        courseDetailId: courseDetail.id,
                        version: courseDetail.version
                    }).then(() => {
                        Dialog.alert({
                            width: '95%',
                            allowHtml: true,
                            className: 'dialog-alert-font-class-course-detail',
                            messageAlign: 'left',
                            title: '报名已完成',
                            message: '报名已完成，最终结果请以课程预约确认函为准，确认函邮件将于11月21-22日发送至您的邮箱。如有任何问题，请您与BMC培训助理进行联系：BMC_trainingassistant@kornferry.com'
                            //message: '您已成功报名本次课程，请仔细阅读课程详情并关注官方通知，以免错过您的培训。如有任何问题，请您与BMC培训助理进行联系：<br>BMC_trainingassistant@gpstrategies.com'
                        })
                        this.$router.replace({name: 'Enrollment', params: {phone: this.phone}})
                    }).catch(e => {
                        console.warn('response', e.response)
                        Dialog.alert({
                            width: '95%',
                            allowHtml: true,
                            className: 'dialog-alert-font-class-course-detail',
                            messageAlign: 'left',
                            title: '非常抱歉',
                            message: '由于本次课程名额已满，您暂时不能报名此课程，尽请关注后续课程的发布，谢谢！如有任何问题，请您与BMC培训助理进行联系<br>BMC_trainingassistant@kornferry.com'
                        })
                        this.$router.replace({name: 'Enrollment', params: {phone: this.phone}})
                    })
                }
            }).catch(() => {
                console.warn('err001')
            });
        },
        isRepeatAppointment(courseDetail) {
            for (let i=0; i<this.enrollmentDetailList.length; i++) {
                let detail = this.enrollmentDetailList[i]
                let res = this.isRepeat(detail.classStartDate, detail.classEndDate, courseDetail.classStartDate, courseDetail.classEndDate)
                if (res) {
                    return true
                }
            }
            return false
        },
        isRepeat(startDate, endDate, selStartDate, selEndDate) {
            const startTime = new Date(moment(startDate)).getTime()
            const endTime = new Date(moment(endDate)).getTime()-1
            const selStartTime = new Date(moment(selStartDate)).getTime()
            const selEndTime = new Date(moment(selEndDate)).getTime()-1
            if (selStartTime < startTime && selEndTime >= startTime && selEndTime <= endTime) {
                return true
            } else if (selStartTime >= startTime && selStartTime < endTime && selEndTime > startTime && selEndTime <= endTime) {
                return true
            } else if (selStartTime>= startTime && selStartTime < endTime && selEndTime > endTime) {
                return true
            } else if (selStartTime < startTime && selEndTime > endTime) {
                return true
            }
            return false
        },
        _test_isRepeat() {
            console.warn('开始测试...')
            let startDate = '2022-06-06T06:00:00Z'
            let endDate = '2022-06-06T08:00:00Z'
            let selStartDate = '2022-06-06T01:00:00Z'
            let selEndDate = '2022-06-06T02:00:00Z'
            let r1 = this.isRepeat(startDate, endDate, selStartDate, selEndDate)
            console.warn('验证第一种情况: false  实际: ', r1)

            selStartDate = '2022-06-06T01:00:00Z'
            selEndDate = '2022-06-06T06:30:00Z'
            let r2 = this.isRepeat(startDate, endDate, selStartDate, selEndDate)
            console.warn('验证第二种情况: true  实际: ', r2)

            selStartDate = '2022-06-06T06:10:00Z'
            selEndDate = '2022-06-06T08:00:00Z'
            let r3 = this.isRepeat(startDate, endDate, selStartDate, selEndDate)
            console.warn('验证第三种情况: true  实际: ', r3)

            selStartDate = '2022-06-06T06:30:00Z'
            selEndDate = '2022-06-06T09:00:00Z'
            let r4 = this.isRepeat(startDate, endDate, selStartDate, selEndDate)
            console.warn('验证第四种情况: true  实际: ', r4)

            selStartDate = '2022-06-06T09:00:00Z'
            selEndDate = '2022-06-06T10:00:00Z'
            let r5 = this.isRepeat(startDate, endDate, selStartDate, selEndDate)
            console.warn('验证第五种情况: false  实际: ', r5)

            selStartDate = '2022-06-06T04:00:00Z'
            selEndDate = '2022-06-06T10:00:00Z'
            let r6 = this.isRepeat(startDate, endDate, selStartDate, selEndDate)
            console.warn('验证第六种情况: true  实际: ', r6)
            console.warn('结束测试...')
        },
        mapClick(courseDetail) {
            if (courseDetail.location) {
                let url = 'http://api.map.baidu.com/geocoder?coord_type=bd09ll&output=html&src=test.bentley.levsoft.cn&location=' + courseDetail.location
                window.location.href = url
            }
        },
        isAppointmentFinish(courseDetail) {
            let status = true
            if (courseDetail) {
                let endTime = new Date(moment(courseDetail.appointmentEndDate).format('YYYY/MM/DD')).getTime() + (1*24*60*60*1000)
                let current = new Date().getTime()
                if (current < endTime) {
                    status = false
                }
            }
            return status
        },
        enrollmentStatus(courseDetail) {
            return (courseDetail.limitCount <= courseDetail.realCount) || this.isAppointmentFinish(courseDetail)
        }
    }
}
</script>

<style lang="less">
.dialog-alert-font-class-course-detail {
  font-family: 'Bentley', 'simhei', RegularEng, Helvetica, 'Avenir', Arial, sans-serif !important;
//   font-size: 12px !important;
  @dialog-width: 400px;
  @dialog-font-size: @font-size-sm;
}
.van-progress {
    border-radius: 0;
}
</style>

<style lang="scss" scoped>
.finish-wrap{
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
.logo {
    width: 375px;
    height: 80px;
    background-color: white;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    img {
        width: 84px;
        height: 42px;
        margin: 20px 20px;
    }
}
.info-box {
    width: 100%;
    height: 105px;
    background-color: rgba(0,50,32,0.7);
    color: #fff;
    display: flex;
    align-items: center;
    // margin-bottom: 10px;
    span {
        width: 100%;
        align-content: center;
        text-align: left;
        font-size: 1.2em;
        margin: 20px;
    }
}
.course-box {
    width: 100%;
    // height: 120px;
    .item-row {
        width: auto;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 5px 10px 5px;
        .item-box {
            width: 100%;
            height: 100%;
            border: 1px solid rgba(227, 227, 227, 1);
            border-radius: 5px;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            .row1 {
                margin: 10px 15px 5px 15px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
            .span1 {
                font-size: 0.9em;
                padding: 5px 10px 5px 0px;
                color: rgba(0,50,32,0.7);
                font-weight: bold;
            }
            .span2 {
                width: 25%;
                font-size: 0.9em;
                padding: 0px 0px 10px 15px;
            }
            .span3 {
                font-size: 0.9em;
                padding: 0px 0px 10px 0px;
            }
            .row2 {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
            }
            .row4 {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                .progress {
                    width: 150px;
                }
            }
        }
        .btn {
            width: 80px;
            height: 16px;
            line-height: 16px;
            border: 1px solid rgba(0,50,32,0.7);
            text-align: center;
            padding: 5px 3px;
            color: #fff;
            background-color: rgba(0,50,32,0.7);
            border-radius: 3px;
            &.disabled {
                background: #999;
            }
        }
    }
    .avatar {
        width: auto;
        margin: 5px 2px 5px;
        display: flex;
        img {
            width: 100%;
            border-radius: 5px;
        }
    }
    .square1 {
        width: 12px;
        height: 14px;
        background-color: #d2d2d4;
        margin: 1px;
        display: flex;
        align-items: center;
        text-align: center;
    }
    .square2 {
        width: 12px;
        height: 14px;
        background-color: rgba(0,50,32,0.7);
        margin: 1px;
        display: flex;
        align-items: center;
        text-align: center;
    }
}

</style>